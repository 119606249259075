import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            translations: {
                NavHome: "Home",
                NavAbout: "About",
                NavWorks: "Our work",
                NavProducts: "Our products",
                NavContact: "Contact",
                ContactTitle: "Contact",
                LocationParagraphTitle: "Our Location",
                LocationParagraph: "Our facilities are located next to Naxos Airport (500m).There you will find a display of our works, plus, you can see our staff on the job. We are here to listen to your requirments and provide solutions to cover your needs, the best way possible. Quality first!",
                Signature: "Vasilis Kostopoulos, director of Naxos Glass",
                Phone: "Phone",
                Mobile: "Mobile",
                Email: "Email",
                Password: "Password",
                AddressName: "Chora of Naxos and Naxos airport",
                LocationTitle: "Location",
                EmailFormTitle: "Send us your message",
                NamePlaceholder: "Your Name *",
                EmailPlaceholder: "Your E-Mail *",
                PhonePlaceholder: "Your Phone",
                MessagePlaceholder: "Your Message *",
                SendButton: "SEND",
                FlexibleGlassesTitle: "Flexible Glasses",
                MirrorsTitle: "Mirrors",
                ShowerGlassesTitle: "Shower Glasses",
                AddNewItemTitle: "New post",
                TitlePlaceholder: "Title",
                UploadImagePlaceholder: "Images",
                DescriptionPlaceholder: "Description",
                PostButton: "ADD",
                InvalidFormValueError: "Please enter a valid value!",
                DeleteProductConfirmation: "Are you sure you want to delete this product?",
                DeleteCategoryConfirmation: "Are you sure you want to delete this category?",
                NewCategoryTitle: "Add a new category",
                ImagePlaceholder: "Image *",
                LogIn: "LOGIN",
                Logout: "Logout",
                EmptyCategoriesMessage: "We have no works at the moment! We will have you updated soon!",
                LinkToMore: "See more...",
                Edit: "Edit",
                ImageChnageButton: "Change Image",
                Cancel: "CANCEL",
                UpdateButton: "UPDATE",
                OurCompanyTitle: "Our Company",
                FlexibleGlassesInNaxos: "Flexible Glasses in Naxos",
                OurCompanyParagraphLine1: "Our company is based in Naxos since 2000. We are providing solutions in glass to cover every need of you in your business or your residence.",
                OurCompanyParagraphLine2: "In our facilities you can see an exhbition of our products, and also you can see our personel in action. We are equiped with state of the art appliances so we can cover your needs, in time and in good quality.",
                OurCompanyParagraphLine3: "Our staff is well experienced and ready to help you.",
                GenericErrorMessage: 'Something went wrong!',
                MessageSent: "Your message has been sent",
                OurPoductsParagraph: "In our company you can find a wide range of products",
                ProductGlasses: "All kinds of glasses",
                ProductGlassDoors: "Glass doors",
                ProductAutomaticGlassDoors: "Automatic doors",
                ProductProtectiveGlasses: "Protective glasses",
                ProductMosquitoWindowNets: "Mosquito windows nets",
                ProductRollerBlindCurtains: "Roller blind curtains",
                ProductFoldingShutters: "Folding window shutters",
                LinkToWorks: "Take a look at our products...",
                GlassConstructions: "Glass constructions",
                Stores: "Stores and businesses",
                BuildingsAndHotels: "Buildings and hotels",
                HomesAndAppartments: "Houses and appartments",
                SliderSubtitle1: "We provide architechtural constructions in all places",
                SliderSubtitle2: "Emphasize your products through elegant solutions",
                SliderSubtitle3: "Full cover of your needs in large constructions",
                SliderSubtitle4: "Renew your home with the quality and applicability of glass",
                TranslationPlaheholder: "English text"
            }
        },
        el: {
            translations: {
                NavHome: "Αρχική",
                NavAbout: "Ποιοι είμαστε",
                NavWorks: "Οι δουλειές μας",
                NavProducts: "Τα προϊόντα μας",
                NavContact: "Επικοινωνία",
                ContactTitle: "Επικοινωνία",
                LocationParagraphTitle: "Πού θα μας βρείτε",
                LocationParagraph: "Οι εγκαταστάσεις μας βρίσκονται πλησίον του αεροδρομίου Νάξου (500m). Εκεί θα βρείτε μια μικρή έκθεση από τις δουλειές μας, και μπορείτε να δείτε το προσωπικό μας εν ώρα εργασίας. Είμαστε έτοιμοι να καλύψουμε τις ανάγκες σας με τον καλύτερο δυνατό τρόπο, με ιδιαίτερη προσοχή στην ποιότητα.",
                Signature: "Βασίλης Κωστόπουλος, υπεύθυνος της Naxos Glass",
                Phone: "Τηλέφωνο",
                Mobile: "Κινητό",
                Email: "Email",
                AddressName: "Αεροδρόμιο και Χώρα, Νάξος",
                LocationTitle: "Τοποθεσία",
                EmailFormTitle: "Επικοινωνήστε μαζί μας",
                NamePlaceholder: "Όνομα *",
                EmailPlaceholder: "E-Mail *",
                Password: "Κωδικός πρόσβασης",
                PhonePlaceholder: "Τηλέφωνο",
                MessagePlaceholder: "Το μήνυμά σας *",
                SendButton: "ΑΠΟΣΤΟΛΗ",
                FlexibleGlassesTitle: "Πτυσσόμενα τζάμια",
                MirrorsTitle: "Καθρέπτες",
                ShowerGlassesTitle: "Ντουζιέρες",
                AddNewItemTitle: "Νέα δηοσίευση",
                TitlePlaceholder: "Τίτλος",
                UploadImagePlaceholder: "Εικόνες",
                DescriptionPlaceholder: "Περιγραφή",
                PostButton: "ΔΗΜΟΣΙΕΥΣΗ",
                InvalidFormValueError: "Μη έγκυρη τιμή!",
                DeleteProductConfirmation: "Είστε σίγουροι πως θελετε να διαγράψετε αυτό το προϊόν;",
                DeleteCategoryConfirmation: "Είστε σίγουροι πως θελετε να διαγράψετε αυτή την κατηγορία;",
                NewCategoryTitle: "Νέα Κατηγορία",
                ImagePlaceholder: "Εικόνα *",
                LogIn: "ΣΥΝΔΕΣΗ",
                Logout: "Αποσύνδεση",
                EmptyCategoriesMessage: "Δεν υπάρχουν ακόμα δουλειές μας στη σελίδα μας.",
                LinkToMore: "Δείτε περισσότερα...",
                Edit: "Επεξεργασία",
                ImageChnageButton: "Αλλαγή εικόνας",
                Cancel: "ΑΚΥΡΩΣΗ",
                UpdateButton: "ΕΝΗΜΕΡΩΣΗ",
                OurCompanyTitle: "Η εταιρία μας",
                FlexibleGlassesInNaxos: "Πτυσσόμενα στη Νάξο",
                OurCompanyParagraphLine1: "Η εταιρία μας δραστηριοποιείται στη Νάξο από το 2000. Παρέχουμε λύσεις σε γυαλί, ώστε να καλύψουμε κάθε σας ανάγκη στην επιχείρηση ή την οικία σας.",
                OurCompanyParagraphLine2: "Στις εγκαταστάσεις μας θα βρείτε μία έκθεση από τα προϊόντα μας, καθώς και θα έχετε την ευκαιρία να δείτε το προσωπικό μας εν ώρα εργασίας. Είμαστε εξοπλισμένοι με μηχανήματα τελευταίας τεχνολογίας, έτσι ώστε να δίνουμε άμεσες και υψηλής ποιότητας λύσεις.",
                OurCompanyParagraphLine3: "Το προσωπικό μας είναι έμπειρο, και πάντα σε ετοιμότητα για να σας βοηθήσει.",
                GenericErrorMessage: 'Κάτι δεν πήγε καλά!',
                MessageSent: "Το μύνημα σας έχει αποσταλεί",
                OurPoductsParagraph: "Στην εταιρία μας θα βρείτε μεγάλη ποικιλία προϊόντων",
                ProductGlasses: "Όλα τα είδη γυαλιού",
                ProductGlassDoors: "Γυάλινες πόρτες",
                ProductAutomaticGlassDoors: "Αυτόματες πόρτες",
                ProductProtectiveGlasses: "Προστατευτικά τζάμια",
                ProductMosquitoWindowNets: "Σίτες",
                ProductRollerBlindCurtains: "Ρολοκουρτίνες",
                ProductFoldingShutters: "Περσίδες",
                LinkToWorks: "Δείτε περισσότερα προϊόντα μας...",
                GlassConstructions: "Κατασκευές από γυαλί",
                Stores: "Καταστήματα και μαγαζιά",
                BuildingsAndHotels: "Κτίρια και ξενοδοχεία",
                HomesAndAppartments: "Οικίες και διαμερίσματα",
                SliderSubtitle1: "Αναλαμβάνουμε αρχιτεκτονικές κατασκευές σε όλους τους χώρους",
                SliderSubtitle2: "Αναδεικνύουμε τα προϊόντα σας με κομψές λύσεις",
                SliderSubtitle3: "Πλήρης κάλυψη αναγκών σε μεγάλες υποδομές",
                SliderSubtitle4: "Ανανεώστε το σπίτι σας με την ποιότητα και την πρακτικότητα του γυαλιού",
                TranslationPlaheholder: "Αγγλική μετάφραση"
            }
        }
    },
    fallbackLng: "en",
    debug: true,
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;