import React from 'react';
import './Location.css';
import { withTranslation } from 'react-i18next'

const mapUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3183.2409878402286!2d25.368841914649384!3d37.075566859851314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14980bfbc225458f%3A0x5525200c86eb1af0!2sNaxos%20Glass!5e0!3m2!1sel!2sgr!4v1587836206602!5m2!1sel!2sgr';

const Location = props => {
    return (
        <div className="location-container">
            <h2 style={{ color: '#0281bf' }}>{props.t('LocationTitle')}</h2>
            <iframe title="Location" src={mapUrl}></iframe>
        </div>
    );
};

export default withTranslation()(Location);