import React, { useState, useEffect, useCallback, Fragment } from 'react';
import useFocus from '../../hooks/Focus';
import classes from './ProductForm.module.css';
import { updateObject } from '../../common/utils';
import ImageInput from '../../components/UI/Input/ImageInput';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import { withTranslation } from 'react-i18next';
import { storage } from '../../firebase/firebase';

const initialFormState = {
    title: {
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        placeholder: 'TitlePlaceholder',
        value: '',
        validation: {
            required: false
        },
        valid: true,
        touched: false
    },
    englishTitle: {
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        placeholder: 'TranslationPlaheholder',
        value: '',
        validation: {
            required: false
        },
        valid: true,
        touched: false
    },
    description: {
        elementType: 'textarea',
        elementConfig: {
            type: 'textarea'
        },
        placeholder: 'DescriptionPlaceholder',
        value: '',
        validation: {
            required: false
        },
        valid: true,
        touched: false
    }
};

const ProductForm = props => {
    const [productForm, setproductForm] = useState(initialFormState);
    const [inputRef, setInputFocus] = useFocus();
    const [upImg, setUpImg] = useState();
    const [imageName, setImageName] = useState('');
    const [imageBlob, setImageBlob] = useState(null);
    const [formIsValid, setFormIsValid] = useState(false);
    const [formIsEmpty, setFormIsEmpty] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [changeImage, setChangeImage] = useState(false);
    const submited = props.submited;
    const initialValues = props.initialValues;

    useEffect(() => {
        if (initialValues) {
            const newForm = { ...productForm };
            newForm.title.value = initialValues.title;
            newForm.title.valid = true;
            newForm.englishTitle.value = initialValues.title;
            newForm.englishTitle.valid = true;
            newForm.description.value = initialValues.description;
            newForm.description.valid = true;
            setproductForm(newForm);
            setFormIsValid(true);
            setFormIsEmpty(false);
            setChangeImage(false);
            setInputFocus();
        };
        // eslint-disable-next-line
    }, [initialValues]);

    const onSelectFile = event => {
        if (event.target.files && event.target.files.length > 0) {
            setImageName(event.target.files[0].name);
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(event.target.files[0]);
            setFormIsValid(true);
            setFormIsEmpty(false);
        }
    };

    const addProduct = useCallback((event) => {
        event.preventDefault();
        setIsLoading(true);
        const formData = {};
        for (let formElementIdentifier in productForm) {
            formData[formElementIdentifier] = productForm[formElementIdentifier].value;
        };
        if (!initialValues || changeImage) {
            const uploadTask = storage.ref(`/images/${imageName}`).put(imageBlob)
            //initiates the firebase side uploading
            uploadTask.on('state_changed',
                (snapShot) => {

                }, (err) => {
                    console.log(err)
                }, () => {
                    storage.ref('images').child(imageName).getDownloadURL()
                        .then(fireBaseUrl => {
                            formData.date = Date.now();
                            if (!!initialValues) {
                                formData.id = initialValues.id;
                                formData.date = initialValues.date;
                            }
                            formData.image = fireBaseUrl;
                            setIsLoading(false);
                            submited(formData);
                            const newFormState = { ...productForm };
                            newFormState.title.value = '';
                            newFormState.title.valid = true;
                            newFormState.title.touched = false;
                            newFormState.englishTitle.value = '';
                            newFormState.englishTitle.valid = true;
                            newFormState.englishTitle.touched = false;
                            newFormState.description.value = '';
                            newFormState.description.touched = false;
                            setproductForm(newFormState);
                            setFormIsEmpty(true);
                            setUpImg(null);
                        });
                });
        } else {
            formData.id = initialValues.id;
            formData.image = initialValues.image;
            formData.date = initialValues.date;
            setIsLoading(false);
            submited(formData);
            const newFormState = { ...productForm };
            newFormState.title.value = '';
            newFormState.title.valid = true;
            newFormState.title.touched = false;
            newFormState.englishTitle.value = '';
            newFormState.englishTitle.valid = true;
            newFormState.englishTitle.touched = false;
            newFormState.description.value = '';
            newFormState.description.touched = false;
            setFormIsEmpty(true);
            setproductForm(newFormState);
            setUpImg(null);
        };
    }, [imageBlob, imageName, productForm, submited, changeImage, initialValues]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedFormElement = updateObject(productForm[inputIdentifier], {
            value: event.target.value,
            valid: true,
            touched: true
        });

        const updatedproductForm = updateObject(productForm, {
            [inputIdentifier]: updatedFormElement
        });

        let formIsValid = true;
        let formIsEmpty = true;
        for (let inputIdentifier in updatedproductForm) {
            formIsEmpty = !updatedproductForm[inputIdentifier].value && formIsEmpty;
        }
        if (!initialValues || changeImage) {
            formIsValid = formIsValid && !!imageBlob;
        }
        setproductForm(updatedproductForm);
        setFormIsValid(formIsValid);
        setFormIsEmpty(formIsEmpty);
    };

    const onEditCancel = event => {
        event.preventDefault();
        setChangeImage(false);
        const newFormState = { ...productForm };
        newFormState.title.value = '';
        newFormState.title.valid = true;
        newFormState.title.touched = false;
        newFormState.englishTitle.value = '';
        newFormState.englishTitle.valid = true;
        newFormState.englishTitle.touched = false;
        newFormState.description.value = '';
        newFormState.description.touched = false;
        setproductForm(newFormState);
        setFormIsEmpty(true);
        setFormIsValid(false);
        setUpImg(null);
        props.cancelEdit();
    };

    const onAddCancel = event => {
        event.preventDefault();
        const newFormState = { ...productForm };
        newFormState.title.value = '';
        newFormState.title.valid = true;
        newFormState.title.touched = false;
        newFormState.englishTitle.value = '';
        newFormState.englishTitle.valid = true;
        newFormState.englishTitle.touched = false;
        newFormState.description.value = '';
        newFormState.description.touched = false;
        setproductForm(newFormState);
        setFormIsEmpty(true);
        setFormIsValid(false);
        setUpImg(null);
    };

    const formElementsArray = [];
    for (let key in productForm) {
        formElementsArray.push({
            id: key,
            config: productForm[key]
        })
    };

    let form = (
        <Fragment>
            {formElementsArray.map((formElement, index) => (
                <Input
                    key={formElement.id}
                    ref={index === 0 ? inputRef : null}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    placeholder={formElement.config.placeholder}
                    value={formElement.config.value}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={(event) => inputChangedHandler(event, formElement.id)} />
            ))}
            {!initialValues || changeImage
                ? <ImageInput
                    changed={onSelectFile}
                    blob={setImageBlob}
                    imgName={imageName}
                    upImg={upImg} />
                : <Button clicked={() => setChangeImage(true)}>{props.t('ImageChnageButton')}</Button>
            }
        </Fragment>
    );

    if (isLoading) {
        form = <Spinner />
    };

    return (
        <div className={classes.formWrapper}>
            <form onSubmit={addProduct}>
                <h2>{props.initialValues ? props.t('Edit') : props.t('AddNewItemTitle')}</h2>
                {form}
                <div>
                    <Button
                        btnType="Success"
                        disabled={!formIsValid}>
                        {props.initialValues ? props.t('UpdateButton') : props.t('PostButton')}
                    </Button>
                    <Button
                        btnType="Danger"
                        disabled={formIsEmpty}
                        clicked={props.initialValues ? onEditCancel : onAddCancel}>
                        {props.t('Cancel')}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default withTranslation()(ProductForm);