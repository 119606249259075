import React from 'react';
import classes from './About.module.css';
import { useTranslation } from 'react-i18next';
import BuildingFront from '../../assets/images/building-front.jpg';

const About = props => {

    const { t } = useTranslation();

    return (
        <div className={classes.AboutWrapper}>
            <div className={classes.FirstRow}>
                <div className={classes.ParagraphWrapper}>
                    <h1 className={classes.Title}>{t('OurCompanyTitle')}</h1>
                    <p>{t('OurCompanyParagraphLine1')}</p>
                    <p>{t('OurCompanyParagraphLine2')}</p>
                    <p>{t('OurCompanyParagraphLine3')}</p>
                </div>
                <img className={classes.BuildingFrontImage} src={BuildingFront} alt={'123'} />
            </div>
        </div>
    );
};

export default About;