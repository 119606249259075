import React from 'react';

import PhotoSlider from '../PhotoSlider/PhotoSlider';
import Preview from '../../components/Preview/Preview';
import Works from '../Works/Works';
import { importAll } from '../../common/utils';
import { FrontSliderTitles, FrontSliderSubTitles } from '../../assets/texts/texts';
import classes from './Front.module.css';

const Front = () => {
    const slideImages = importAll(require.context('../../assets/images/slider', false, /\.(png|jpe?g|svg)$/));

    return (
        <div className={classes.Wrapper}>
            <PhotoSlider slideImages={slideImages} titles={FrontSliderTitles} subTitles={FrontSliderSubTitles} />
            <Preview title="NavWorks" link="/works">
                <Works source="front" />
            </Preview>
        </div>
    )
};

export default Front;