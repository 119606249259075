import React, { Fragment, useRef, useState, useCallback } from 'react';
import ReactCrop from 'react-image-crop';

import classes from './Input.module.css'

const ImageInput = props => {
    const inputClasses = [classes.InputElement];
    const imgRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: props.aspect, keepSelection: true });
    const imageName = props.imgName;
    const setImageBlob = props.blob;
    const [previewUrl, setPreviewUrl] = useState();

    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);

    const makeClientCrop = useCallback(crop => {
        if (imgRef.current && crop.width && crop.height) {
            const canvas = document.createElement('canvas');
            const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
            const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(
                imgRef.current,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );

            return new Promise((resolve, reject) => {
                canvas.toBlob(blob => {
                    if (!blob) {
                        reject(new Error('Canvas is empty'));
                        return;
                    }
                    blob.name = imageName;
                    setImageBlob(blob);
                    window.URL.revokeObjectURL(previewUrl);
                    setPreviewUrl(window.URL.createObjectURL(blob));
                }, 'image/jpeg');
                resolve();
            });
        }
    }, [previewUrl, imageName, setImageBlob]);


    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
    };

    if (props.cropAspect) {
        const newCrop = { ...crop };
        newCrop.aspect = props.cropAspect;
        setCrop(newCrop);
    }

    return (
        <Fragment>
            <div className={classes.Input}>
                <label className={classes.Label}>{props.label}</label>
                <input
                    className={inputClasses.join(' ')}
                    type="file"
                    accept="image/*"
                    onChange={props.changed} />
            </div>
            <ReactCrop
                src={props.upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={c => setCrop(c)}
                onComplete={makeClientCrop}
            />
        </Fragment>
    )
};

export default ImageInput;