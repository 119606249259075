import React, { useState, Fragment, useEffect } from 'react';
import ProductsCollection from '../ProductsCollection/ProductsCollection';
import { ReactComponent as MinusIcon } from '../../../assets/images/minus-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import Modal from '../../../components/UI/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './ProductCategory.css';

const ProductCategory = props => {
    const { t, i18n } = useTranslation();
    const isAuth = useSelector(state => !!state.token);
    const [isExpanded, setIsExpanded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const { selected } = props;

    useEffect(() => {
        if (selected) {
            setIsExpanded(true);
        }
    }, [selected])

    const onClickHandler = () => {
        setIsExpanded(!isExpanded);
        props.clicked();
    };

    const deleteCategoryHandler = () => {
        setShowModal(false);
        props.onDelete(props.id);
    }

    const getTitle = () => {
        if (i18n.language !== 'el' && props.englishTitle) {
            return props.englishTitle
        } else {
            return props.title;
        }
    }

    return (
        <Fragment >
            <div className={(isExpanded || !props.showFull) ? 'product-container-expanded' : 'product-container'}
                style={location.pathname !== '/' && !isExpanded && props.showFull ? { width: '30%' } : null}
                onClick={isExpanded ? onClickHandler : () => { }}>
                <div className={(isExpanded || !props.showFull) ? 'category-title-expanded' : 'category-title'} onClick={!isExpanded ? onClickHandler : () => { }}>
                    {getTitle()}
                </div>
                {(!isExpanded && props.showFull)
                    ? <Fragment>
                        <img className="product-img" src={props.image} alt={props.id} onClick={!isExpanded ? onClickHandler : () => { }} />
                        {isAuth && location.pathname !== '/'
                            ? <div className="buttons-container">
                                <MinusIcon className="icon" onClick={() => setShowModal(true)} />
                                <EditIcon className="icon" onClick={() => props.openEditForm(props.id)} />
                            </div>
                            : null
                        }
                    </Fragment>
                    : null
                }
            </div>
            {isExpanded
                ? <ProductsCollection
                    id={props.id}
                    shouldShow={props.shouldShow}
                    toggleShow={props.toggleShow} />
                : null}
            <Modal show={showModal} modalClosed={() => setShowModal(false)}>
                <div className="modal-wrapper">
                    <p className="delete-category-message">{t('DeleteCategoryConfirmation')}</p>
                    <Button
                        btnType="Danger"
                        clicked={deleteCategoryHandler}>
                        OK
                    </Button>
                    <Button
                        btnType="Danger"
                        clicked={() => setShowModal(false)}>
                        {t('Cancel')}
                    </Button>
                </div>
            </Modal>
        </Fragment>
    );
};

export default ProductCategory;