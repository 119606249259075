import React from 'react';
import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const NavigationItems = (props) => {
    const isAuth = useSelector(state => !!state.token);

    return (
        <ul className={classes.NavigationItems}>
            <NavigationItem link="/" exact>{props.t('NavHome')}</NavigationItem>
            <NavigationItem link="/about">{props.t('NavAbout')}</NavigationItem>
            <NavigationItem link="/products">{props.t('NavProducts')}</NavigationItem>
            <NavigationItem link="/works">{props.t('NavWorks')}</NavigationItem>
            <NavigationItem link="/contact">{props.t('NavContact')}</NavigationItem>
            {isAuth ? <NavigationItem link="/logout">{props.t('Logout')}</NavigationItem> : null}
        </ul>
    )
};

export default withTranslation()(NavigationItems);