import React, { useState, useEffect, useCallback } from 'react';
import ProductCategory from '../Products/ProductCategory/ProductCategory';
import AddCategoryForm from '../AddCategoryForm/AddCategoryForm';
import Spinner from '../../components/UI/Spinner/Spinner';
import { ReactComponent as BackIcon } from '../../assets/images/back.svg';
import { withTranslation } from 'react-i18next';
import axios from '../../axios-instance';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import './Works.css';

const Works = props => {
    const { isAuth, token } = useSelector(state => {
        return { isAuth: !!state.token, token: state.token }
    });
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isCategorySelected, setIsCategorySelected] = useState(false);
    const [emptyState, setEmptyState] = useState(true);
    const [editCategoryData, setEditCategoryData] = useState(null);
    const [hasError, setHasError] = useState(false);
    const location = useLocation();
    const history = useHistory();

    const fetchCategories = () => {
        setIsLoading(true);
        axios.get('/productCategories.json').then(response => {
            let initialCategories = [];
            let shouldShowAll = false;
            if (location.search.includes('?category')) {
                const query = new URLSearchParams(location.search);
                const category = query.get('category')
                response.data[category].selected = true;
                // setIsCategorySelected(true);
            }
            for (let key in response.data) {
                initialCategories.push({
                    title: response.data[key].title,
                    englishTitle: response.data[key].englishTitle,
                    image: response.data[key].image,
                    shouldShow: response.data[key].shouldShow,
                    date: response.data[key].date,
                    id: key,
                    selected: response.data[key].selected || false
                });
                shouldShowAll = shouldShowAll || response.data[key].shouldShow;
            };
            if (location.search.includes('?category')) {
                initialCategories.sort((a, b) =>
                    (a.selected && !b.selected) ? -1 : (!a.selected && b.selected) ? 1 : 0);
            } else {
                initialCategories.sort((a, b) => new Date(b.date) - new Date(a.date));
            }
            setCategories(initialCategories);
            setEmptyState(shouldShowAll);
            setIsLoading(false);
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    };

    useEffect(fetchCategories, []);

    useEffect(() => {
        setIsCategorySelected(categories.some(category => category['selected'] === true));
    }, [categories])

    const toggleSelection = id => {
        const categoriesCopy = [...categories];
        categoriesCopy.forEach(category => {
            if (category.id === id) {
                category.selected = !category.selected;
            };
        });
        categoriesCopy.sort((a, b) =>
            (a.selected && !b.selected) ? -1 : (!a.selected && b.selected) ? 1 : 0);
        setCategories(categoriesCopy);
    };

    const addCategoryHandler = useCallback(newCategory => {
        setIsLoading(true);
        axios.post(`/productCategories.json?auth=${token}`, newCategory).then(response => {
            newCategory.id = response.data.name;
            newCategory.selected = false;
            const newCategories = [newCategory, ...categories];
            setCategories(newCategories);
            setIsLoading(false);
        }).catch(error => {
            console.log(error);
            setHasError(true);
            setIsLoading(false);
        });
    }, [categories, token]);

    const editCategoryHandler = useCallback(modifiedCategory => {
        setIsLoading(true);
        axios.put(`/productCategories/${modifiedCategory.id}.json?auth=${token}`, modifiedCategory).then(response => {
            modifiedCategory.selected = false;
            const categoryIndex = categories.findIndex(category => category.id === modifiedCategory.id);
            const newCategories = [...categories];
            newCategories[categoryIndex] = modifiedCategory;
            setCategories(newCategories);
            setIsLoading(false);
            setEditCategoryData(null);
        }).catch(error => {
            console.log(error);
            setHasError(true);
            setIsLoading(false);
        });
    }, [categories, token]);

    const deleteCategoryHandler = useCallback(id => {
        setIsLoading(true);
        axios.delete(`/productCategories/${id}.json?auth=${token}`).then(response => {
            const newCategoriesList = categories.filter(category => category.id !== id);
            setCategories(newCategoriesList);
            setIsLoading(false);
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    }, [categories, token]);

    const toggleShow = useCallback(id => {
        const modifiedCategories = categories.filter(category => category.id !== id);
        const categoryToBeModified = categories.find(category => category.id === id);
        const updatedCategory = {
            title: categoryToBeModified.title,
            englishTitle: categoryToBeModified.englishTitle,
            image: categoryToBeModified.image,
            date: categoryToBeModified.date,
            shouldShow: !categoryToBeModified.shouldShow
        };
        axios.put(`/productCategories/${id}.json?auth=${token}`, updatedCategory)
            .then(response => {
                setCategories(modifiedCategories);
                let shouldShowAll = false;
                modifiedCategories.forEach(category => {
                    shouldShowAll = shouldShowAll || category.shouldShow;
                    setEmptyState(shouldShowAll);
                });
            })
            .catch(error => {
                console.log(error);
            });
    }, [categories, token]);

    const showOpenEditForm = id => {
        setEditCategoryData(categories.find(category => category.id === id));
    };

    const hideAll = useCallback(() => {
        if (location.search.includes('?category')) {
            history.push('/works');
            location.search = '';
        }
        setIsCategorySelected(false);
        window.scrollTo(0, 0);
        fetchCategories();
        // eslint-disable-next-line
    }, [location, history]);

    let displayCategories = categories.map(category => {
        return (category.shouldShow || isAuth)
            ? <ProductCategory
                key={category.id}
                id={category.id}
                shouldShow={category.shouldShow}
                selected={category.selected}
                toggleShow={toggleShow}
                title={category.title}
                englishTitle={category.englishTitle}
                image={category.image}
                showFull={!isCategorySelected}
                clicked={() => toggleSelection(category.id)}
                onDelete={deleteCategoryHandler}
                openEditForm={showOpenEditForm} />
            : null
    });

    if (props.source === 'front') {
        let index = 0;
        displayCategories = categories.map(category => {
            if (category.shouldShow) {
                index++;
            }
            return ((category.shouldShow || isAuth) && index <= 3)
                ? <NavLink className="link-to-self" key={category.id} to={`/works?category=${category.id}`} exact>
                    <ProductCategory
                        id={category.id}
                        shouldShow={category.shouldShow}
                        toggleShow={toggleShow}
                        title={category.title}
                        englishTitle={category.englishTitle}
                        image={category.image}
                        showFull={!isCategorySelected}
                        clicked={() => toggleSelection(category.id)}
                        onDelete={deleteCategoryHandler} />
                </NavLink>
                : null
        });
    };

    if (isLoading) {
        displayCategories = <Spinner />
    };

    let form = <AddCategoryForm submited={addCategoryHandler} error={hasError} clearError={() => setHasError(false)} />

    if (!!editCategoryData) {
        form = (
            <AddCategoryForm
                submited={editCategoryHandler}
                initialValues={editCategoryData}
                cancelEdit={() => setEditCategoryData(null)}
                error={hasError}
                clearError={() => setHasError(false)} />
        );
    };

    return (
        <div className={!isCategorySelected ? 'works-wrapper' : null}>
            <div className={isCategorySelected ? 'categories-container-selected' : 'categories-container'}>
                {displayCategories}
                {isCategorySelected ? <BackIcon className="back-icon" onClick={hideAll} /> : null}
            </div>
            {!emptyState && !isAuth ? <p className="empty-state">{props.t('EmptyCategoriesMessage')}</p> : null}
            {isAuth && !isCategorySelected && location.pathname !== '/'
                ? <div className="category-form-wrapper">
                    {form}
                </div>
                : null}
        </div>
    );
};

export default withTranslation()(Works);