import React, { Fragment, useState, useCallback } from 'react';
import './EmailForm.css';
import { updateObject, checkValidity } from '../../common/utils';

import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import { useTranslation } from 'react-i18next';
import Spinner from '../../components/UI/Spinner/Spinner';
import axios from '../../axios-instance';

const intialFormState = {
    name: {
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        placeholder: 'NamePlaceholder',
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    email: {
        elementType: 'input',
        elementConfig: {
            type: 'email'
        },
        placeholder: 'EmailPlaceholder',
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    phone: {
        elementType: 'input',
        elementConfig: {
            type: 'tel'
        },
        placeholder: 'PhonePlaceholder',
        value: '',
        validation: {
            required: false
        },
        valid: true,
        touched: false
    },
    message: {
        elementType: 'textarea',
        elementConfig: {
            type: 'textarea',
            rows: 5
        },
        placeholder: 'MessagePlaceholder',
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    }
};

const EmailForm = props => {
    const { t } = useTranslation();
    const [emailForm, setEmailForm] = useState(intialFormState);
    const [formIsValid, setFormIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formIsEmpty, setFormIsEmpty] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [messageSent, setMessageSent] = useState(false);

    const orderHandler = useCallback((event) => {
        event.preventDefault();
        setIsLoading(true);
        const formData = {};
        for (let formElementIdentifier in emailForm) {
            formData[formElementIdentifier] = emailForm[formElementIdentifier].value;
        };
        axios.post('https://us-central1-naxos-glass.cloudfunctions.net/mailer', formData)
            .then(response => {
                setEmailForm(intialFormState);
                setFormIsValid(false);
                setFormIsEmpty(true);
                setIsLoading(false);
                setMessageSent(true);
            })
            .catch(error => {
                console.log(error);
                setHasError(true);
                setIsLoading(false);
            })
    }, [emailForm]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedFormElement = updateObject(emailForm[inputIdentifier], {
            value: event.target.value,
            valid: checkValidity(event.target.value, emailForm[inputIdentifier].validation),
            touched: true
        });

        const updatedEmailForm = updateObject(emailForm, {
            [inputIdentifier]: updatedFormElement
        });

        let formIsValid = true;
        let formIsEmpty = true;
        for (let inputIdentifier in updatedEmailForm) {
            formIsValid = updatedEmailForm[inputIdentifier].valid && formIsValid;
            formIsEmpty = !updatedEmailForm[inputIdentifier].value && formIsEmpty;
        }
        setEmailForm(updatedEmailForm);
        setFormIsValid(formIsValid);
        setFormIsEmpty(formIsEmpty);
    }

    const onCancelHandler = event => {
        event.preventDefault();
        setEmailForm(intialFormState);
        setFormIsValid(false);
        setFormIsEmpty(true);
        setHasError(false);
    }

    const formElementsArray = [];
    for (let key in emailForm) {
        formElementsArray.push({
            id: key,
            config: emailForm[key]
        })
    }

    let form = formElementsArray.map(formElement => (
        <Input
            key={formElement.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            placeholder={formElement.config.placeholder}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={(event) => inputChangedHandler(event, formElement.id)} />
    ));

    if (isLoading) {
        form = <Spinner />
    }

    return (
        <Fragment>
            <h2 className="label">{t('EmailFormTitle')}</h2>
            {messageSent ? <p style={{ color: '#5C9210' }}>{t('MessageSent')}</p> : null}
            {hasError ? <p style={{ color: '#944317' }}>{t('GenericErrorMessage')}</p> : null}
            <form onSubmit={orderHandler} className="form">
                {form}
                <Button
                    btnType="Success"
                    disabled={!formIsValid}>
                    {t('SendButton')}
                </Button>
                <Button
                    btnType="Danger"
                    disabled={formIsEmpty}
                    clicked={onCancelHandler}>
                    {t('Cancel')}
                </Button>
            </form>
        </Fragment>
    );
};

export default EmailForm;