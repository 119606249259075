import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhotoSlider from '../PhotoSlider/PhotoSlider';
import { NavLink } from 'react-router-dom';
import { importAll } from '../../common/utils';

import classes from './Products.module.css';

const Products = props => {
    const { t } = useTranslation();
    // eslint-disable-next-line
    const [products, setProducts] = useState([
        'ProductGlasses',
        'MirrorsTitle',
        'ProductGlassDoors',
        'ProductAutomaticGlassDoors',
        'FlexibleGlassesTitle',
        'ShowerGlassesTitle',
        'ProductProtectiveGlasses',
        'ProductMosquitoWindowNets',
        'ProductRollerBlindCurtains',
        'ProductFoldingShutters'
    ]);
    const slideImages = importAll(require.context('../../assets/images/products-slider', false, /\.(png|jpe?g|svg)$/));

    return (
        <div className={classes.ProductsWrapper}>
            <div className={classes.FirstRow}>
                <div className={classes.ParagraphWrapper}>
                    <h1 className={classes.Title}>{t('NavProducts')}</h1>
                    <p>{t('OurPoductsParagraph')}</p>
                    <ul>
                        {products.map(product =>
                            <li key={product}>
                                {t(product)}
                            </li>
                        )}
                    </ul>
                    <NavLink className={classes.Link} to="works" exact>{t('LinkToWorks')}</NavLink>
                </div>
                <PhotoSlider source="Products" duration={3500} pauseOnHover={true} slideImages={slideImages} />
            </div>
        </div>
    );
};

export default Products;