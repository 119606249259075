import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import './App.css';

import Layout from './hoc/Layout/Layout';
import Front from './containers/Front/Front';
import About from './containers/About/About';
import Products from './containers/Products/Products';
import Works from './containers/Works/Works';
import Contact from './components/Contact/Contact';
import Auth from './containers/Auth/Auth';
import Logout from './containers/Auth/Logout/Logout';
import { useDispatch } from 'react-redux';
import * as actions from './store/actions/auth';

const App = props => {

  const [showFooter, setShowFooter] = useState(true);
  const urlPath = props.location.pathname;
  const dispatch = useDispatch();
  const onTryAutoSignup = useCallback(() => dispatch(actions.authCheckState()), [dispatch]);

  useEffect(() => {
    if (urlPath === '/contact' || urlPath === '/admin-naxos') {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
  }, [urlPath]);

  useEffect(() => onTryAutoSignup(), [onTryAutoSignup]);

  let routes = (
    <Switch>
      <Route path="/about" component={About} />
      <Route path="/products" component={Products} />
      <Route path="/works" component={Works} />
      <Route path="/contact" component={Contact} />
      <Route path="/admin-naxos" component={Auth} />
      <Route path="/logout" component={Logout} />
      <Route path="/" exact render={(props) => <Front {...props} />} />
      <Redirect to="/" />
    </Switch>
  );

  return (
    <div>
      <Layout showFooter={showFooter}>
        {routes}
      </Layout>
    </div >
  );
}

export default withRouter(App);
