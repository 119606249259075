import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './Preview.module.css';
import { withTranslation } from 'react-i18next';

const Preview = props => {

    return (
        <div className={classes.Wrapper}>
            <h2 className={classes.Title}>{props.t(props.title)}</h2>
            {props.children}
            <NavLink className={classes.Link} to={props.link} exact>{props.t('LinkToMore')}</NavLink>
        </div>
    )
};

export default withTranslation()(Preview);