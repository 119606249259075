import React from 'react';
import './Address.css';

import { ReactComponent as FacebookLogo } from '../../../assets/images/facebook-logo.svg';
import { ReactComponent as InstagramLogo } from '../../../assets/images/instagram-logo.svg';
import { withTranslation } from 'react-i18next';

const facebookPageUrl = 'https://www.facebook.com/Naxos-Glass-144453455706565';
const instagramPageUrl = 'https://www.instagram.com/naxos_glass/';


const Address = props => {
    const openWebsite = site => window.open(site);

    return (
        <div className="address-container">
            <h2 style={{ color: '#0281bf' }}>{props.t('ContactTitle')}</h2>
            <address>
                <ul>
                    <li>{props.t('Phone')} <a href="tel:+302285026885">22850-26885</a></li>
                    <li>{props.t('Mobile')} <a href="tel:+306977668517">6977668517</a></li>
                    <li>{props.t('Email')} <a href="mailto:info@naxosglass.gr">info@naxosglass.gr</a></li>
                    <li>{props.t('AddressName')}</li>
                </ul>
            </address>
            <div className="links">
                <FacebookLogo className="logo" onClick={() => openWebsite(facebookPageUrl)} />
                <InstagramLogo className="logo" onClick={() => openWebsite(instagramPageUrl)} />
            </div>
        </div>
    );
};

export default withTranslation()(Address);