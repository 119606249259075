import React from 'react';
import classes from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItmes/NavigaitonItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import { ReactComponent as GreekFlag } from '../../../assets/images/el-gr.svg';
import { ReactComponent as UKFlag } from '../../../assets/images/en-uk.svg';
import { withTranslation } from 'react-i18next'

const toolbar = props => {

    const setLangGreek = () => {
        props.i18n.changeLanguage('el');
    };

    const setLangEnglish = () => {
        props.i18n.changeLanguage('en');
    };

    return (
        <header className={classes.Toolbar}>
            <DrawerToggle clicked={props.drawerToggleClicked} />
            <div className={classes.Logo}>
                <Logo src="toolbar" />
            </div>
            <nav>
                <div className={classes.DesktopOnly}>
                    <NavigationItems />
                </div>
                {props.i18n.language === 'el'
                    ? <UKFlag className={classes.Icon} onClick={setLangEnglish} />
                    : <GreekFlag className={classes.Icon} onClick={setLangGreek} />
                }

            </nav>
        </header>
    );

};

export default withTranslation()(toolbar);