import React, { useState, useEffect } from 'react';
import { Fade } from 'react-slideshow-image';
import { useTranslation } from 'react-i18next';
import './PhotoSlider.css';

const PhotoSlider = props => {
    const [bluredStyle, setBluredStyle] = useState({});
    const [textOpacity, setTextOpacity] = useState({ opacity: '100%' });
    const { t } = useTranslation();
    const source = props.source;

    useEffect(_ => {
        if (source !== 'Products') {
            const handleScroll = _ => {
                if (window.pageYOffset > 0.4 * window.innerHeight) {
                    setBluredStyle({ filter: 'blur(8px)' });
                    setTextOpacity({ opacity: 0 });
                } else {
                    setBluredStyle(null);
                    setTextOpacity({ opacity: '100%' })
                }
            }
            window.addEventListener('scroll', handleScroll);
            return _ => {
                window.removeEventListener('scroll', handleScroll)
            }
        }
    }, [source]);

    const properties = {
        duration: props.duration || 5000,
        transitionDuration: 500,
        infinite: true,
        indicators: true,
        arrows: true,
        pauseOnHover: props.pauseOnHover || false
    };

    return (
        <div className={source !== 'Products' ? 'slide-container' : 'slide-container-small'}>
            <Fade {...properties}>
                {props.slideImages.map((image, index) => (
                    <div key={image} className={source !== 'Products' ? 'each-slide' : 'each-slide-small'} style={bluredStyle}>
                        <div style={{ 'backgroundImage': `url(${image})` }}>
                            {!!props.titles
                                ? <h1 className="texts" style={textOpacity}>{t(props.titles[index])}</h1>
                                : null}
                            {!!props.subTitles
                                ? <h1 className="subtitles" style={textOpacity}>{t(props.subTitles[index])}</h1>
                                : null}
                        </div>
                    </div>
                ))}
            </Fade>
        </div >
    )
};

export default PhotoSlider;