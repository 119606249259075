import firebase from 'firebase/app';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyA5ULKhr3otU6U4jEtu6WyUVtvJsOLfV_M",
    authDomain: "naxos-glass.firebaseapp.com",
    databaseURL: "https://naxos-glass.firebaseio.com",
    projectId: "naxos-glass",
    storageBucket: "naxos-glass.appspot.com",
    messagingSenderId: "42144778984",
    appId: "1:42144778984:web:a3dd1da455891d338a975d",
    measurementId: "G-6WRL3DGKKT"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//analytics is optional for this tutoral
// firebase.analytics();

const storage = firebase.storage()

export {
    storage, firebase as default
};