import React, { useState, useCallback, useEffect } from 'react';
import Modal from '../../../components/UI/Modal/Modal';
import Button from '../../../components/UI/Button/Button';
import ProductForm from '../../ProductForm/ProductForm';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { ReactComponent as MinusIcon } from '../../../assets/images/minus-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios-instance';
import { useSelector } from 'react-redux';
import './ProductsCollection.css';

const ProductsCollection = props => {
    const { t, i18n } = useTranslation();
    const { isAuth, token } = useSelector(state => {
        return { isAuth: !!state.token, token: state.token }
    });
    const [productList, setProductList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [editProductData, setEditProductData] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const categoryId = props.id;
    const shouldShow = props.shouldShow;
    const toggleShow = props.toggleShow;

    useEffect(() => {
        setIsLoading(true);
        axios.get(`/products/${categoryId}.json`).then(response => {
            let initialProducts = [];
            for (let key in response.data) {
                initialProducts.push({
                    title: response.data[key].title,
                    englishTitle: response.data[key].englishTitle,
                    image: response.data[key].image,
                    date: response.data[key].date,
                    id: key,
                    description: response.data[key].description,
                });
            };
            initialProducts.sort((a, b) => new Date(b.date) - new Date(a.date));
            setProductList(initialProducts);
            setIsLoading(false);
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    }, [categoryId])

    const onProductDelete = useCallback(id => {
        setShowModal(false);
        setIsLoading(true);
        axios.delete(`/products/${categoryId}/${id}.json?auth=${token}`).then(response => {
            const newProductList = productList.filter(product => product.id !== id);
            setProductList(newProductList);
            if (newProductList.length === 0) {
                toggleShow(categoryId);
            }
            setIsLoading(false);
            setSelectedProductId(null);
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
            setSelectedProductId(null);

        });
    }, [categoryId, productList, toggleShow, token]);

    const onProductAdd = useCallback(product => {
        setIsLoading(true);
        axios.post(`/products/${categoryId}.json?auth=${token}`, product).then(response => {
            product.id = response.data.name;
            const newProductList = [product, ...productList];
            setProductList(newProductList);
            !shouldShow && toggleShow(categoryId);
            setIsLoading(false);
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    }, [categoryId, shouldShow, toggleShow, productList, token]);

    const editProductHandler = useCallback(modifiedProduct => {
        setIsLoading(true);
        axios.put(`/products/${categoryId}/${modifiedProduct.id}.json?auth=${token}`, modifiedProduct).then(response => {
            const productIndex = productList.findIndex(product => product.id === modifiedProduct.id);
            const newProducts = [...productList];
            newProducts[productIndex] = modifiedProduct;
            setProductList(newProducts);
            setEditProductData(null);
            !shouldShow && toggleShow(categoryId);
            setIsLoading(false);
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    }, [categoryId, productList, shouldShow, toggleShow, token]);


    const openEditForm = id => {
        setEditProductData(productList.find(product => product.id === id));
    };

    const openModal = id => {
        setShowModal(true);
        setSelectedProductId(id);
    };

    const getTitle = (title, englishTitle) => {
        if (i18n.language !== 'el' && englishTitle) {
            return englishTitle
        } else {
            return title;
        }
    }

    let form = <ProductForm submited={onProductAdd} />

    if (editProductData) {
        form = (
            <ProductForm
                submited={editProductHandler}
                initialValues={editProductData}
                cancelEdit={() => setEditProductData(null)} />
        )
    };

    return (
        <div className="products-wrapper">
            <ul className="products-list">
                {productList.map(product => {
                    return (
                        <li key={product.id}>
                            <h3>{getTitle(product.title, product.englishTitle)}</h3>
                            <img src={product.image} alt={product.id} />
                            <p>{t(product.description)}</p>
                            {isAuth
                                ? <div className="form-buttons-container">
                                    <MinusIcon className="product-icon" onClick={() => openModal(product.id)} />
                                    <EditIcon className="product-icon" onClick={() => openEditForm(product.id)} />
                                </div>
                                : null
                            }
                        </li>
                    );
                })}
            </ul>
            <Modal show={showModal} modalClosed={() => setShowModal(false)}>
                <p className="delete-message">{t('DeleteProductConfirmation')}</p>
                <Button
                    btnType="Danger"
                    clicked={() => onProductDelete(selectedProductId)}>
                    OK
                </Button>
                <Button
                    btnType="Danger"
                    clicked={() => setShowModal(false)}>
                    {t('Cancel')}
                </Button>
            </Modal>
            {isLoading ? <Spinner /> : null}
            {isAuth
                ? <div className="product-form-wrapper">
                    {form}
                </div>
                : null}
        </div>
    );
};

export default ProductsCollection;