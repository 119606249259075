import React from 'react';
import './Footer.css';

import Address from '../Contact/Address/Address';
import Location from '../Contact/Location/Location';

const Footer = props => {
    return (
        <div className="footer-container">
            <div className="information-container">
                <Location label="Location" />
                <Address label="Contact" />
            </div>
            <p className="copywrite-disclaimer">{String.fromCharCode(0xA9) + ' 2020. All rights reserved.'}</p>
        </div>

    );
};

export default Footer;