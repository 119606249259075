import React, { useState, useEffect } from 'react';
import Input from '../../components/UI/Input/Input';
import Spinner from '../../components/UI/Spinner/Spinner';
import Button from '../../components/UI/Button/Button';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classes from './Auth.module.css';
import * as actions from '../../store/actions/auth';
import { updateObject, checkValidity } from '../../common/utils';
import { withTranslation } from 'react-i18next';

const Auth = props => {
    const [loginFormData, setLoginFormData] = useState({
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: 'Email'
            },
            value: '',
            validation: {
                required: true,
                isEmail: true
            },
            valid: false,
            touced: false
        },
        password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Password'
            },
            value: '',
            validation: {
                required: true,
                minLength: 6
            },
            valid: false,
            touced: false
        }
    });
    const { loading, error, isAuthenticated, authRedirectPath } = useSelector(state => {
        return {
            loading: state.loading,
            error: state.error,
            isAuthenticated: !!state.token,
            authRedirectPath: state.authRedirectPath
        }
    });

    const dispatch = useDispatch();
    const onAuth = (email, password, isSignup) => dispatch(actions.auth(email, password));

    useEffect(() => {
        if (authRedirectPath !== '/') {
            dispatch(actions.setAuthRedirectPath('/'));
        }
    }, [authRedirectPath, dispatch]);

    const inputChangedHandler = (event, controlName) => {
        const updatedControls = updateObject(loginFormData, {
            [controlName]: updateObject(loginFormData[controlName], {
                value: event.target.value,
                valid: checkValidity(event.target.value, loginFormData[controlName].validation),
                touched: true
            })
        });
        setLoginFormData(updatedControls);
    }

    const submitHandler = event => {
        event.preventDefault();
        onAuth(loginFormData.email.value, loginFormData.password.value, false);
    }

    const formElementsArray = [];
    for (let key in loginFormData) {
        formElementsArray.push({
            id: key,
            config: loginFormData[key]
        })
    }

    let form = formElementsArray.map(formElement => (
        <Input
            key={formElement.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            placeholder={formElement.config.elementConfig.placeholder}
            value={formElement.config.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={(event) => inputChangedHandler(event, formElement.id)} />

    ));

    if (loading) {
        form = <Spinner />
    }

    let errorMessage = null;

    if (error) {
        errorMessage = (
            <p>{error.message}</p>
        )
    }

    let authRedirect = null;
    if (isAuthenticated) {
        authRedirect = <Redirect to={authRedirectPath} />
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Auth}>
                {authRedirect}
                {errorMessage}
                <form onSubmit={submitHandler}>
                    {form}
                    <Button btnType="Success">{props.t('LogIn')}</Button>
                </form>
            </div>
        </div>
    );

};

export default withTranslation()(Auth);