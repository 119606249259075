export const FrontSliderTitles = [
    'GlassConstructions',
    'Stores',
    'BuildingsAndHotels',
    'HomesAndAppartments'
];

export const FrontSliderSubTitles = [
    'SliderSubtitle1',
    'SliderSubtitle2',
    'SliderSubtitle3',
    'SliderSubtitle4'
];