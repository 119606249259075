import React from 'react';
import greekLogo from '../../assets/images/logo-el.png';
import englishLogo from '../../assets/images/logo-en.png';
import kbLogo from '../../assets/images/logo.jpg';
import classes from './Logo.module.css';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const logo = props => {
    const imgSrc = () => {
        if (props.src === 'toolbar') {
            return props.i18n.language === 'el' ? greekLogo : englishLogo
        } else {
            return kbLogo;
        }
    };

    return (
        <NavLink to="/" exact>
            <div className={classes.Logo}>
                <img src={imgSrc()} alt="Logo" />
            </div>
        </NavLink>
    )
};

export default withTranslation()(logo);