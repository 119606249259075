import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import useFocus from '../../hooks/Focus';
import classes from './AddCategoryForm.module.css';
import { updateObject } from '../../common/utils';
import Input from '../../components/UI/Input/Input';
import ImageInput from '../../components/UI/Input/ImageInput';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import { storage } from '../../firebase/firebase';
import 'react-image-crop/dist/ReactCrop.css';

const initialFormState = {
    title: {
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        placeholder: 'TitlePlaceholder',
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    englishTitle: {
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        placeholder: 'TranslationPlaheholder',
        value: '',
        validation: {
            required: false
        },
        valid: true,
        touched: false
    }
};

const AddCategoryForm = props => {
    const { t } = useTranslation();
    const [upImg, setUpImg] = useState();
    const [imageName, setImageName] = useState('');
    const [imageBlob, setImageBlob] = useState(null);
    const [formData, setFormData] = useState(initialFormState);
    const [changeImage, setChangeImage] = useState(false);
    const [formIsValid, setFormIsValid] = useState(false);
    const [formIsEmpty, setFormIsEmpty] = useState(true);
    const inputImage = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [inputRef, setInputFocus] = useFocus();
    const submited = props.submited;
    const initialValues = props.initialValues;

    useEffect(() => {
        if (initialValues) {
            const newForm = { ...formData };
            newForm['title'].value = initialValues.title;
            newForm['title'].valid = true;
            newForm['englishTitle'].value = initialValues.englishTitle;
            newForm['englishTitle'].valid = true;
            setFormData(newForm);
            setFormIsEmpty(false);
            setFormIsValid(true);
            setChangeImage(false);
            setInputFocus();
        };
        // eslint-disable-next-line
    }, [initialValues]);

    const onSelectFile = event => {
        if (event.target.files && event.target.files.length > 0) {
            setImageName(event.target.files[0].name);
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(event.target.files[0]);
            setFormIsValid(formData['title'].valid);
            setFormIsEmpty(false);
        }
    };

    const addCategory = useCallback((event) => {
        event.preventDefault();
        setIsLoading(true);
        const data = {};
        if (!initialValues || changeImage) {
            const uploadTask = storage.ref(`/images/${imageName}`).put(imageBlob)
            //initiates the firebase side uploading
            uploadTask.on('state_changed',
                (snapShot) => {

                }, (err) => {
                    console.log(err)
                }, () => {
                    storage.ref('images').child(imageName).getDownloadURL()
                        .then(fireBaseUrl => {
                            data.date = Date.now();
                            if (!!initialValues) {
                                data.id = initialValues.id;
                                data.date = initialValues.date;
                            };
                            data.title = formData['title'].value;
                            data.englishTitle = formData['englishTitle'].value;
                            data.image = fireBaseUrl;
                            data.shouldShow = false;
                            setIsLoading(false);
                            submited(data);
                            setFormData(initialFormState);
                            setFormIsEmpty(true);
                            inputImage.current = null;
                            setUpImg(null);
                        });
                });
        } else {
            data.id = initialValues.id;
            data.title = formData['title'].value;
            data.englishTitle = formData['englishTitle'].value;
            data.image = initialValues.image;
            data.shouldShow = initialValues.shouldShow;
            data.date = initialValues.date;
            setIsLoading(false);
            submited(data);
            setFormData(initialFormState);
            setFormIsEmpty(true);
            inputImage.current = null;
            setUpImg(null);
        };
    }, [formData, submited, imageName, imageBlob, changeImage, initialValues]);

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedFormElement = updateObject(formData[inputIdentifier], {
            value: event.target.value,
            valid: true,
            touched: true
        });

        const updatedForm = updateObject(formData, {
            [inputIdentifier]: updatedFormElement
        });

        let isFormValid = true;
        let formIsEmpty = true;
        for (let inputIdentifier in updatedForm) {
            formIsEmpty = !updatedForm[inputIdentifier].value && formIsEmpty;
        }
        if (!initialValues || changeImage) {
            isFormValid = isFormValid && !!imageBlob;
            setFormIsEmpty(!updatedForm['title'].value);
        } else {
            setFormIsEmpty(false);
        }
        setFormIsValid(isFormValid);
        setFormData(updatedForm);
    };

    const onEditCancel = event => {
        event.preventDefault();
        setChangeImage(false);
        setFormData(initialFormState);
        setFormIsValid(false);
        setFormIsEmpty(true);
        props.clearError();
        inputImage.current = null;
        setUpImg(null);
        props.cancelEdit();
    };

    const onAddCancel = event => {
        event.preventDefault();
        setFormData(initialFormState);
        setFormIsEmpty(true);
        setFormIsValid(false);
        props.clearError();
        inputImage.current = null;
        setUpImg(null);
    };

    const formElementsArray = [];
    for (let key in formData) {
        formElementsArray.push({
            id: key,
            config: formData[key]
        })
    };

    let form = (
        <Fragment>
            {formElementsArray.map((formElement, index) => (
                <Input
                    key={formElement.id}
                    ref={index === 0 ? inputRef : null}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    placeholder={formElement.config.placeholder}
                    value={formElement.config.value}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={(event) => inputChangedHandler(event, formElement.id)} />
            ))}
            {!initialValues || changeImage
                ? < ImageInput
                    changed={onSelectFile}
                    blob={setImageBlob}
                    imgName={imageName}
                    upImg={upImg}
                    aspect={16 / 9} />
                : <Button clicked={() => setChangeImage(true)}>{t('ImageChnageButton')}</Button>}
        </Fragment>
    );

    if (isLoading) {
        form = <Spinner />
    };

    return (
        <div className={classes.formWrapper}>
            <form onSubmit={addCategory}>
                <h2>{props.initialValues ? t('Edit') : t('NewCategoryTitle')}</h2>
                {props.error ? <p style={{ color: '#944317' }}>{t('GenericErrorMessage')}</p> : null}
                {form}
                <div>
                    <Button
                        btnType="Success"
                        disabled={!formIsValid}>
                        {props.initialValues ? t('UpdateButton') : t('PostButton')}
                    </Button>
                    <Button
                        btnType="Danger"
                        disabled={formIsEmpty}
                        clicked={props.initialValues ? onEditCancel : onAddCancel}>
                        {t('Cancel')}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AddCategoryForm;