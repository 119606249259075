import React from 'react';
import './Contact.css';

import Address from './Address/Address';
import Location from './Location/Location';
import EmailForm from '../../containers/EmailForm/EmailForm';
import { withTranslation } from 'react-i18next';

const Contact = props => {

    return (
        <div className="contact-container">
            <div className='location-wrapper'>
                <div className="text">
                    <h2 style={{ color: '#0281bf' }}>{props.t('LocationParagraphTitle')}</h2>
                    <p>{props.t('LocationParagraph')}</p>
                    <p>{props.t('Signature')}</p>
                </div>
                <Location />
            </div>
            <div className="contact-wrapper">
                <div className="form-wrapper">
                    <EmailForm />
                </div>
                <Address label="Contact us" />
            </div>
            <p className="copywrite-disclaimer">{String.fromCharCode(0xA9) + ' 2020. All rights reserved.'}</p>
        </div >
    );
};

export default withTranslation()(Contact);