import React, { useRef, useImperativeHandle, forwardRef } from 'react';

import classes from './Input.module.css'
import { useTranslation } from 'react-i18next';

const Input = (props, ref) => {
    const { t } = useTranslation();
    const innerRef = useRef(null);
    useImperativeHandle(ref, () => innerRef.current);
    let inputElement = null;
    const inputClasses = [classes.InputElement];

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
    }

    switch (props.elementType) {
        case ('input'):
            inputElement = <input
                ref={innerRef}
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                placeholder={t(props.placeholder)}
                value={t(props.value)}
                onChange={props.changed} />
            break;
        case ('textarea'):
            inputElement = <textarea
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                placeholder={t(props.placeholder)}
                value={props.value}
                onChange={props.changed} />
            break;
        case ('select'):
            inputElement = (
                <select
                    className={inputClasses.join(' ')}
                    value={props.value}
                    onChange={props.changed}>
                    {props.elementConfig.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.displayValue}
                        </option>
                    ))}
                </select>
            )
            break;
        default:
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                placeholder={t(props.placeholder)}
                value={props.value}
                onChange={props.changed} />
    }

    let validationError = null;
    if (props.invalid && props.touched) {
        validationError = <p className={classes.ValidationError}>{t('InvalidFormValueError')}</p>;
    }

    return (
        <div className={classes.Input}>
            <label className={classes.Label}>{props.label}</label>
            {inputElement}
            {validationError}
        </div>
    )
};

export default forwardRef(Input);